<script>
import $ from 'jquery';

// import Enlighten from './enlighten/app/index';

import components from './components';

export default {
    data() {
        return {
            activeVideo: false,
            menuActive: false
        }
    },
    components,
    methods: {
        menuTakeover() {
            this.menuActive = true;
            $('body').addClass('takeover takeover-menu');
            this.$nextTick(this.focusFirst);
        },
        menuClose() {
            this.menuActive = false;
            $('body').removeClass('takeover takeover-menu');
            $('#menu-opener').focus();
        },
        focusFirst() {
            $('#main-menu .close').focus();
        },
        focusWatch(event) {
            if (this.menuActive && !document.getElementById('main-menu').contains(event.relatedTarget)) {
                this.focusFirst();
            }
        },
        back() {
            window.history.back();
        },
        panicLeave(event) {
            if (localStorage)
                localStorage.clear();
            if (sessionStorage)
                localStorage.clear();
            if (history)
                history.replaceState({},'index','/');
            location.href = event.currentTarget.href;
        }
    },
    created() {
        document.addEventListener('focusout', this.focusWatch, true);
        $(document).keyup(e => {
            if (this.menuActive && e.key === "Escape") {
                $('#main-menu .close').click();
            }
        });
        window.onpopstate = (event) => {
            this.$root.$emit('popState', event);
        };
    },
    mounted() {
        this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
            if (collapseId === 'main-menu' && !isJustShown && this.menuActive)
                this.menuClose();
        });
/*
        if (!$('body').hasClass('om') && $('article').length > 0) {
            const sv = new Enlighten({language: 'sv'});
            sv.parseElement('article', false);
        }
*/
    }
}
</script>

<style lang="sass">
@import style/globals
</style>
