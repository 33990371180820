<template>
    <form @submit.prevent>
        <div class="row">
            <header class="col-lg-8 offset-lg-2">
                <span class="label">{{ $gettext('Police report - step by step') }}</span>
                <h1>{{ number }}. {{ data.title }}</h1>
            </header>
        </div>
        <div class="row" v-for="field in data.fields" :key="field.name">
            <div :class="field.wide ? 'col': ' col-lg-8 offset-lg-2'">
                <component 
                    :is="field.component"
                    :field="field"
                    :class="{collapse: field.depends, show: getFieldVisibility(field)}" />
            </div>
        </div>
        <div class="row">
            <div class="col-lg-8 offset-lg-2 navigation">
                <button v-if="hasPrevious" @click.prevent="previous" class="btn btn-secondary">{{ $gettext('Previous') }}</button>
                <span v-else></span>
                <button v-if="hasNext" @click.prevent="next" :disabled="!complete" class="btn btn-primary">{{ $gettext('Next') }}</button>
            </div>
        </div>
    </form>
</template>
<script>
export default {
    data() {
        return {
            complete: false,
            hasPrevious: true,
            hasNext: true
        }
    },
    props: {
        data: Object,
        number: Number,
        loaded: Boolean
    },
    methods: {
        previous() {
            this.$parent.previous();
        },
        next() {
            if (this.complete)
                this.$parent.next();
        },
        checkCompleted() {
            this.complete = this.$children.find(input => !input.valid) === undefined;
            this.$parent.setCurrentComplete(this.complete);
        },
        getFieldVisibility(field) {
            let show = true
            if (field.depends) {
                let dependField = this.data.fields.find(f => f.name===field.depends);
                show = dependField.value !== null;
                if (show && field.dependValue !== undefined)
                    show = dependField.value === field.dependValue;
            }
            return show;
        }
    },
    mounted() {
        this.checkCompleted();
    },
    created() {
        this.$on('value::set', data => {
            this.data.fields.find(field => field.name === data.name).value = data.value;
            this.checkCompleted();
        });
    },
}
</script>