import Start from './Start.vue';
import Region from './Region.vue';
import Report from './Report.vue';
import Evidence from './Evidence.vue';
import Send from './Send.vue';
import Done from './Done.vue';

export default [
    Start,
    Region,
    Report,
    Evidence,
    Send,
    Done
]
