import CardDelete from './CardDelete.vue';
import CardSave from './CardSave.vue';
import CardsSavedMenu from './CardsSavedMenu.vue';
import ShareButton from './ShareButton.vue';
import SiteSearch from './SiteSearch.vue';
import SiteSearchOverlay from './SiteSearchOverlay.vue';
import VideoPopup from './VideoPopup.vue';
import ConfirmDialog from './ConfirmDialog.vue';
import BrowserClear from './BrowserClear.vue';
import SiteRelease from './SiteRelease.vue';
import { Support } from './support';
import CrimeReport from './CrimeReport/index.vue';


export default {
    SiteRelease,
    CardDelete,
    CardSave,
    CardsSavedMenu,
    ShareButton,
    SiteSearch,
    SiteSearchOverlay,
    VideoPopup,
    ConfirmDialog,
    BrowserClear,
    Support,
    CrimeReport
}
