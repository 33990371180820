const p = str => '<p>' + str + '</p>';

export default {
    props: {
        title: String
    },
    methods: {
        clearData() {
            let deleted = false;
            if (localStorage && localStorage.length) {
                deleted = true;
                localStorage.clear();
            }
            if (sessionStorage && sessionStorage.length) {
                deleted = true;
                sessionStorage.clear();
            }
            let options = deleted ?
                {title: this.$gettext('Deleted data'), description: p(this.$gettext('Deleted your browser data for Näthatshjälpen.'))} :
                {title: this.$gettext('Nothing to delete'), description: p(this.$gettext('You have no data to delete.'))};
            options.description += p(this.$gettext('If you want to hide your visit completely, you will also need to clear your browser history.'));
            this.$root.$emit('confirm::open', options);
            this.$root.$emit('clearBrowserData');
        }
    }
}
