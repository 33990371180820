import $ from 'jquery';
export default {
    data: () => {
        return {
            active: false
        };
    },
    props: {
        videoId: String,
        imgSrc: String,
        title: String
    },
    methods: {
        open() {
            $('body').addClass('takeover takeover-video');
            this.active = true;
            this.$root.activeVideo = this.videoId;
            history.pushState({videoId: this.videoId}, 'video', '#' + this.videoId);
        },
        close() {
            $('body').removeClass('takeover takeover-video');
            this.active = false;
            this.$root.activeVideo = false;
        },
    },
    created() {
        this.$root.$on('popState', () => {
            if (this.active) {
                this.close();
            }
        });
        $(document).keyup((e) => {
            if (this.active && e.key === "Escape") {
                history.back();
            }
        });
    }
}
