import { render, staticRenderFns } from "./Start.vue?vue&type=template&id=49d62852&"
import script from "./Start.vue?vue&type=script&lang=js&"
export * from "./Start.vue?vue&type=script&lang=js&"
import style0 from "./Start.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports