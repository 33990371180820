<template>
    <div id="site-release" :class="'counters-' + time.length">
        <div id="countdown">
            <span id="days" v-if="time.days">{{ time.days }} dagar</span>
            <span id="hours" v-if="time.hours">{{ time.hours }}</span>
            <span id="minutes" v-if="time.minutes">{{ time.minutes }}</span>
            <span id="seconds">{{ time.seconds }}</span>
        </div>
    </div>
</template>
<script>
import { setTimeout } from 'timers';
function twoDigits(num) {
    return num.toLocaleString(undefined, {minimumIntegerDigits: 2});
}

export default {
    data() {
        return {
            seconds_: this.seconds
        }
    },
    props: {
        seconds: Number
    },
    computed: {
        time() {
            let time = { length: 0 };
            time.days = ~~(this.seconds_ / 86400);
            time.hours = ~~((this.seconds_ % 86400) / 3600);
            time.minutes = ~~((this.seconds_ % 3600) / 60);
            time.seconds = ~~this.seconds_ % 60;
            if (time.days)
                time.hours = twoDigits(time.hours);
            if (time.hours || time.minutes)
                time.minutes = twoDigits(time.minutes);
            if (time.minutes)
                time.seconds = twoDigits(time.seconds);
            Object.keys(time).forEach(key => {
                if (key !== 'length' && time[key])
                    time.length++;
            });
            return time;
        }
    },
    created() {
        setInterval(() => {
            if (this.seconds_ > 0)
                this.seconds_--;
            else
                setTimeout(() => location.reload(), 1000);
        }, 1000);
    }
}
</script>
<style lang="sass">
@import ../style/variables

#site-release
    position: fixed
    top: 0
    bottom: 0
    left: 0
    width: 100%
    background-color: $color-dark
    color: $color-light
    transition: background-color 2s ease-in
    &.counters-0
        background-color: $color-light

#countdown
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
    font-size: 6vw
    #days
        margin-right: .6em
    #hours::after,
    #minutes::after
        content: ':'

    .counters-0 &,
    .counters-1 &
        font-size: 30vw
    .counters-2 &
        font-size: 15vw
    .counters-3 &
        font-size: 12vw
</style>
