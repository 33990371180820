<template>
    <article class="row">
        <header class="col-lg-8 offset-lg-2">
            <span class="label">{{ $gettext('Police report - step by step') }}</span>
            <h1>{{ number }}. {{ data.title }}</h1>
            <img src="/static/polisanmalan.svg" alt="Folksamling på väg" />
            <p>{{ $gettext("Just because a crime is committed on the Internet, that doesn't make it less serious. To change the views of online crimes, the justice system needs to understand the extent of the problem. That's why we think it's important that you report online crimes.") }}</p>
        </header>
        <div class="col-lg-8 offset-lg-2">
            <p class="important">OBS! <strong>{{ $gettext('Call 112') }}</strong> {{ $gettext("directly if you're in subject to danger, threat or in an emergency.") }}</p>
            <p>{{ $gettext("The Internet is for real! We guide you, step by step, to help you create and submit your report. It takes about 15-30 minutes, depending on how extensive your report is.") }}</p>
            <p>{{ $gettext("Please gather digital evidence before starting, to create the best possible conditions for your report. However, the police always has a responsibility to investigate, no matter what evidence you supply!") }}</p>
            <p><a :href="$gettext('/en/a/gather-digital-evidence/')">{{ $gettext('Guide') }}: {{ $gettext('Gather digital evidence') }}</a></p>
            <p>{{ $gettext('To protect your identity online, no personal information or images will be uploaded. You will attach those on your own, before sending the email.') }}</p>
            <p></p>
            <div class="navigation centered">
                <button class="btn btn-primary" @click="next" :disabled="!loaded">{{ $gettext('Get started') }}</button>
            </div>
        </div>
    </article>
</template>
<script>
import Base from './Base.vue';

export default {
    extends: Base,
}
</script>
<style lang="sass">
@import '../../../style/mixins'

article
    .important
        background-color: #fff
        font-size: 20px
        text-align: center
        padding: 1.4rem .4rem 1.2rem
        +media-breakpoint-up(lg)
            margin-left: -60px
            margin-right: -60px
            font-size: 24px
        strong
            font-weight: 500
</style>
