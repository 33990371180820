<template>
    <div id="site-search-overlay" :class="{active, ready}">
        <div class="container">
            <site-search v-if="subPage" :title="title" :src="src"></site-search>
            <div class="row" v-if="active">
                <div v-if="loading || query.length === 0" class="col-md-10 offset-md-1">
                    <p v-if="loading"><i class="material-icons">refresh</i></p>
                    <div v-else-if="query.length === 0">
                        <p><em>{{ $gettext('Enter your search words to find advice and articles.') }}</em></p>
                        <hr>
                        <p><strong>{{ $gettext('Search hint') }}: </strong><em>{{ $gettext('Limit your search by prepending +/- to words. Example: +facts -advice.') }}</em></p>
                    </div>
                </div>
                <div v-else class="col-12 results">
                    <h3 class="medium">{{ $gettext('Results') }}</h3>
                    <div v-if="results.length === 0">
                        <p><em>{{ $gettext('Found no results for your search') }}</em></p>
                        <hr>
                        <p><strong>{{ $gettext('Search hint') }}: </strong><em>{{ $gettext('Expand your search by using * as a wildcard. Example: integrity*.') }}</em></p>
                    </div>
                    <div class="card-columns">
                        <div class="card nathat-card" v-for="res in results" :key="res.href" :class="res.type.toLowerCase()">
                            <a v-if="res.img" :href="res.href">
                                <div class="card-img-container">
                                    <img :src="res.img" alt="">
                                </div>
                                <div class="card-body">
                                    <h3 class="card-title">
                                        <span class="presentation">
                                            <span class="label">{{ res.label }}</span>
                                            <span class="title">{{ res.title }}</span>
                                        </span>
                                    </h3>
                                    <div class="card-text" v-html="res.description"></div>
                                </div>
                            </a>
                            <div v-else>
                                <card-save :id="res.id"></card-save>
                                <div class="card-body">
                                    <h3 class="card-title">
                                        <span class="presentation">
                                            <span class="label">{{ res.label }}</span>
                                            <span class="title">{{ res.title }}</span>
                                        </span>
                                    </h3>
                                    <div class="card-text" v-html="res.descriptionHTML"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script src="./SiteSearch.js"></script>
<style lang="sass">
@import ../style/variables

#site-search-overlay
    position: fixed
    top: 0
    bottom: 0
    left: 0
    right: 0
    background-color: rgba($color-dark, 0)
    transition: background-color .2s
    display: none
    z-index: 1050
    color: $color-light
    .container
        height: 100%
        opacity: 0
        transition: opacity .2s
        .row
            height: 100%
            overflow-y: auto

    &.active
        display: block

    &.ready
        background-color: rgba($color-dark, $takeover-opacity)
        .container
            opacity: 1

    a:focus .card-img-container
        filter:  brightness(95%)
</style>
<script>
import CardSave from './CardSave.vue';
import SiteSearch from './SiteSearch.vue';

export default {
    data: () => {
        return {
            active: false,
            ready: false,
            results: [],
            query: '',
            loading: false
        };
    },
    props: {
        title: String,
        src: String,
        subPage: Boolean
    },
    components: {CardSave, SiteSearch},
}
</script>
