export default {
    props: {
        results: Array,
        loading: Boolean,
        currentTags: Array
    },
    computed: {
        title() {
            if (this.loading)
                return this.$gettext('Loading support data...');
            else if (this.results.length)
                return this.$ngettext('Showing one organization', 'Showing %s organizations', this.results.length);
            return this.$gettext('No organization matches your filter');
        }
    }
}
