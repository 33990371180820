import steps from './steps'
import widgets from '../widgets'
import $ from 'jquery'

export default {
    props: {
        src: String
    },
    data() {
        return {
            steps,
            stepData: [],
            currentStep: 0,
            stepsComplete: [true,false,false,false,false],
            template: ''
        }
    },
    methods: {
        scrollTop() {
            const $body = $('body,html'),
                  top = $(this.$el).offset().top;
            if ($body.scrollTop() > top)
                $body.animate({ scrollTop: top }, ()=>{
                    $('body').removeClass('scrolled-up');
                });
        },
        previous() {
            this.currentStep -= 1;
            this.scrollTop();
        },
        next() {
            this.currentStep += 1;
            this.scrollTop();
        },
        go(step) {
            if (this.checkAvailable(step))
                this.currentStep = step;
            this.$el.querySelector(':focus').blur();
        },
        setCurrentComplete(value) {
            this.stepsComplete[this.currentStep] = value;
        },
        checkAvailable(step) {
            for (var i=0; i<this.stepsComplete.length; i++) {
                if (step === i)
                    return true;
                if (!this.stepsComplete[i])
                    return false;
            }
        }
    },
    computed: {
        current() {
            return this.steps[this.currentStep];
        },
        visibleSteps() {
            return this.steps.filter(step => !step.hidden)
        },
        hasData() {
            return !!this.stepData.find(step => {
                return step.fields.find(field => field.component && field.value);  // Hidden if no component
            });
        },
        isComplete() {
            return this.currentStep === (this.steps.length - 1);
        }
    },
    created() {
        window.addEventListener('beforeunload', event => {
            if (this.hasData && !this.isComplete)
                if (!confirm(this.$gettext('You have unsaved data in your crime report. Are you sure you want to leave the page?'))) {
                    event.preventDefault();
                    event.returnValue = '';  // Chrome (hrmurmle)
                }
        });
        $.get(this.src)
        .then(data => {
            this.template = data.template;
            data.steps.forEach(step => {
                step.fields.forEach(field => {
                    if (field.component)
                        field.component = widgets[field.component];
                    if (field.value === undefined)
                        field.value = null;
                });
            });
            this.stepData = data.steps;
        })
    }
}
