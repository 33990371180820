<template>
    <article class="row">
        <header class="col-lg-8 offset-lg-2">
            <span class="label">{{ $gettext('Police report - step by step') }}</span>
            <h1>{{ $gettext('Thank you for reporting') }}</h1>
            <img src="/static/polisanmalan.svg" alt="Folksamling på väg" />
        </header>
        <div class="col-lg-8 offset-lg-2">
            <p>{{ $gettext("Today there are few crimes on the Internet that get reported or resolved. We want to make it easier. You should be able to report online crimes directly on the web. We also see the need for more resources towards investigating online crimes. For that to happen, the justice system and politicians needs to understand how wide spread the problems are. If everyone reports crimes, the statistics will show the truth. That's necessary for real change to happen. The Internet is for real.") }}</p>
            <p>{{ $gettext("If you don't get a confirmation from the police, with a reference number, sent it again. The police is obliged to receive crime reports, no matter how they get them. You have the right to email and report crimes on the Internet!") }}</p>
        </div>
        <div class="col-lg-8 offset-lg-2 navigation">
            <button @click.prevent="previous" class="btn btn-secondary">{{ $gettext('Previous') }}</button>
        </div>
    </article>
</template>
<script>
import Base from './Base.vue';

export default {
    extends: Base,
    title: 'Tack',
    hidden: true
}
</script>
<style lang="sass">
@import '../../../style/mixins'

article
    .important
        background-color: #fff
        font-size: 20px
        text-align: center
        padding: 1.4rem .4rem 1.2rem
        +media-breakpoint-up(lg)
            margin-left: -60px
            margin-right: -60px
            font-size: 24px
        strong
            font-weight: 500
</style>
