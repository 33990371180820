<template>
    <div class="infotext">
        <h2 v-if="field.label">{{ field.label }}</h2>
        <help-text :field="field"/>
        <help-collapse :field="field"/>
    </div>
</template>
<script>
import HelpText from './HelpText.vue';
import HelpCollapse from './HelpCollapse.vue';
export default {
    props: {
        field: Object
    },
    components: {
        HelpText,
        HelpCollapse
    },
    data() {
        return {
            valid: true
        }
    },
}
</script>
<style lang="sass">
@import ../../style/variables
.infotext
    blockquote
        padding-left: 35px
        padding-right: 60px
        padding-bottom: 10px
        border-left: 3px solid darken($color-mint, 30%)
        margin-top: 1.6rem
        font-size: 19px
        white-space: pre-line
</style>
