import $ from 'jquery';
const lunr = require('lunr');
require("lunr-languages/lunr.stemmer.support")(lunr)
require("lunr-languages/lunr.sv")(lunr)

const split_whitespace = text => text.split(/\s+/).filter(w => w.length);

const SEARCH_INDEX_REF = 'href';
const SEARCH_INDEX_KEYS = ['title','description','label','tags'];

export default {
    data: () => {
        return {
            query: '',
            active: false,
            docs: {},
            loaded: false,
            origScrollPos: 0,
            index: null,
            ready: false
        };
    },
    props: {
        'leadIn': String,
        'title': String,
        'src': String
    },
    methods: {
        createSearchIndex(docs) {
            this.loaded = true;
            docs.forEach(doc => {
                this.docs[doc.href] = doc;
            });
            this.index = lunr(function() {
                this.use(lunr.sv)
                this.ref(SEARCH_INDEX_REF);
                SEARCH_INDEX_KEYS.forEach(key => this.field(key));
                docs.forEach(function(doc) {
                    this.add(doc);
                }, this);
            });
        },
        fetchData() {
            this.overlay.loading = true;
            $.get(this.src)
            .done(data => {
                // if (sessionStorage)
                //     sessionStorage.searchData = JSON.stringify(data);
                this.createSearchIndex(data);
                this.search();
            })
            .fail(() => {
                alert(this.$gettext('Could not load search data'));
            })
            .always(() => {
                this.overlay.loading = false;
            });
        },
        enable() {
            this.active = true;
            if (!this.loaded && !this.overlay.loading) {
                if (sessionStorage && sessionStorage.searchData) {
                    this.createSearchIndex(JSON.parse(sessionStorage.searchData));
                    this.search();
                } else  {
                    this.fetchData();
                }
            }
            $('body').addClass('takeover takeover-search');
            if (this.overlay.subPage) {
                this.ready = true;
                setTimeout(() => {
                    $(this.$el).find('input').focus();
                    this.setFullHeight();
                }, 200);
            } else {
                const $doc = $('html, body');
                this.origScrollPos = $doc.scrollTop();
                $doc.animate({
                    scrollTop: $(this.$el).offset().top,
                }, () => {
                    this.setFullHeight();
                    this.ready = true;
                });
            }
            this.active = true;
            history.pushState({searchMode: true}, 'search', '#search');
        },
        disable() {
            $('body').removeClass('takeover takeover-search');
            if (this.overlay.subPage) {
                this.active = false;
            } else {
                const $doc = $('html, body');
                $(this.$el).find(':focus').blur();
                $doc.animate({
                    scrollTop: this.origScrollPos,
                }, () => {
                    this.active = false;
                });
            }
            this.ready = false;
        },
        back() {
            history.back();
        },
        handleResize() {
            this.setFullHeight();
        },
        setFullHeight() {
            const $results = $(this.overlay.$el).find('.container');
            if (this.overlay.subPage) {
                $results.css('height', $(window).height() - $(this.$el).height() - 20);
            } else {
                $results.css('padding-top', $(this.$el).height() + 20);
            }
        },
        // On index page: Disable all scrolling (focusWatch doesn't always work).
        scrollWatch() {
            if (!this.overlay.subPage && this.active && this.ready)
                $('html, body').scrollTop($(this.$el).offset().top);
        },
        focusWatch(event) {
            if (this.$root.$refs.confirmDialog.active)
                return;
            if (this.active && event.relatedTarget) {
                const target = event.relatedTarget;
                if (!this.$el.contains(target) && !this.overlay.$el.contains(target)) {
                    const allElements = $('*');
                    const targetIndex = allElements.index(target);
                    const forward = targetIndex > allElements.index(event.target);
                    const overlayIndex = allElements.index(this.overlay.$el);
                    const searchIndex = allElements.index(this.$el);
                    if (this.overlay.subPage) {
                        if (forward) {
                            $(this.$el).find('.close').focus();
                        } else {
                            this.focusResults($(this.$el).find('button'), 'last');
                        }
                    } else {
                        if (searchIndex < targetIndex && targetIndex < overlayIndex) {
                            if (forward) {
                                this.focusResults($(this.$el).find('button'));
                            } else {
                                $(this.$el).find('input').focus();
                            }
                        } else if (forward) {
                            $(this.$el).find('button').focus();
                        } else {
                            this.focusResults($(this.$el).find('input'), 'last');
                        }
                    }
                }
            }
        },
        focusResults(backupTarget, side='first') {
            if (this.overlay.results.length !== 0) {
                $(this.overlay.$el).find('.results').find('button,a')[side]().focus();
            } else if (typeof backupTarget !== 'undefined') {
                backupTarget.focus();
            }
        },
        search() {
            this.$root.$refs.siteSearchOverlay.results = this.getResults();
        },
        getResults() {
            if (this.query.length < 2 || !this.loaded)
                return [];
            const words = split_whitespace(this.query);
            return this.index.query(query => {
                words.forEach(word => {
                    let params = {}
                    if (word[0] === '-')
                        params.presence = lunr.Query.presence.PROHIBITED;
                    if (word[0] === '+')
                        params.presence = lunr.Query.presence.REQUIRED;
                    word = word.toLowerCase().replace(/[~+\-^]/, '');
                    if (word.length >= 6)
                        params.editDistance = 1;
                    if (word.length)
                        query.term(word, params);
                });
            }).map(res => this.docs[res.ref]);
        },
        focus() {
            if (sessionStorage && sessionStorage.latestSearch)
                this.query = sessionStorage.latestSearch;
            if (!this.overlay.active && this.query) {
                this.enable();
            }
        }
    },
    created() {
        document.addEventListener('focusout', this.focusWatch, true);
        window.addEventListener('scroll', this.scrollWatch);
        $(window).resize(this.handleResize);
        this.$root.$on('popState', () => {
            if (this.active) {
                this.disable();
            }
        });
        $(document).keyup(e => {
            if (this.active && e.key === "Escape") {
                history.back();
            }
        });
        this.$root.$on('site-search::enable', this.enable);
    },
    watch: {
        query(value) {
            if (sessionStorage)
                sessionStorage.latestSearch = value;
            if (!this.active && value) {
                this.enable();
            }
            this.search();
            this.overlay.query = value;
        },
        active(value) {
            this.overlay.active = value;
        },
        ready(value) {
            this.overlay.ready = value;
        }
    },
    computed: {
        overlay() {
            return this.$root.$refs.siteSearchOverlay;
        }
    }
}
