<template>
    <div class="textarea-input" :class="{invalid: touched && !valid}">
        <h2><label :for="field.name">{{ field.label }}</label></h2>
        <help-text :field="field"/>
        <textarea @blur="touched = true" :required="field.required" class="form-control" :id="field.name" :name="field.name" v-model="value" />
        <span class="validation" v-if="touched && !valid"><span class="exclamation">!</span> {{ validity }}</span>
        <help-collapse :field="field"/>
    </div>
</template>
<script>
import BaseInput from './BaseInput.vue';

export default {
    extends: BaseInput,
    data() {
        return {
            tagName: 'textarea'
        }
    }
}
</script>
<style lang="sass">
.textarea-input
    textarea
        border: 1px solid #000
        border-radius: 0
        height: 10em
    &.invalid textarea
        border-bottom: 4px solid #000
</style>
