import $ from 'jquery';
import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import VueYouTubeEmbed from 'vue-youtube-embed';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import App from './App.vue'


Vue.use(BootstrapVue);
Vue.use(VueYouTubeEmbed);

Vue.config.productionTip = false;
Vue.config.devtools = true;

/*
const gettext = gettext || ( str => str );
const ngettext = ngettext || ((strs, strp, c) => c === 1 ? strs : strp);
const interpolate = interpolate || ( str => str );
*/

const GettextPlugin = {
    install(Vue) {
        Vue.prototype.$gettext = str => { return gettext(str); };
        Vue.prototype.$ngettext = (strs, strp, c) => { return interpolate(ngettext(strs, strp, c), [c]); };
    }
    // Vue.prototype.$myMethod = function (methodOptions) {
}
Vue.use(GettextPlugin);

new Vue(App).$mount('#app');

/*
 *  Reveal menu @scroll up
 */
$(() => {
    let lastPosition = 0;
    $(window).scroll(() => {
        const newPosition = window.pageYOffset;
        if (newPosition === 0) {
            $('body').removeClass('scrolled');
        } else {
            $('body').addClass('scrolled');
            if (lastPosition > newPosition) {
                $('body').addClass('scrolled-up');
            } else {
                $('body').removeClass('scrolled-up');
            }
        }
        lastPosition = newPosition;
    });
    if (window.pageYOffset > 0) {
        $('body').addClass('scrolled scrolled-up');
    }
});
