<template>
    <div class="radio-buttons">
        <h2>{{ field.label }}</h2>
        <help-text :field="field"/>
        <div v-for="(option, k) in field.options" :key="k" role="radiogroup">
            <label role="radio" @keydown="keydown" :tabindex="(field.value !== null ? field.value === option[0] : k === 0) ? 0 : -1" :class="{active: field.value === option[0]}" :for="field.name + k" :aria-checked="value === option[0]">
                <input :id="field.name + k" type="radio" :name="field.name" :value="option[0]" v-model="value" />
                <span class="radio-display"></span>
                {{ option[1] }}
            </label>
        </div>
        <help-collapse :field="field"/>
    </div>
</template>
<script>
import HelpText from './HelpText';
import HelpCollapse from './HelpCollapse';
export default {
    data() {
        return {
            value: this.field.value
        }
    },
    props: {
        field: Object
    },
    components: {
        HelpText,
        HelpCollapse
    },
    methods: {
        keydown(event) {
            let flag = true;
            switch(event.code) {
                case 'ArrowUp':
                case 'ArrowLeft':
                    if (this.index > 0)
                        this.value = this.field.options[this.index-1][0];
                    break;
                case 'ArrowDown':
                case 'ArrowRight':
                    if (this.index < (this.field.options.length - 1))
                        this.value = this.field.options[this.index+1][0];
                    break;
                case 'Space':
                case 'Enter':
                    if (this.value === null)
                        this.value = this.field.options[0][0];
                    break;
                default:
                    flag = false;
            }
            if (flag) {
                event.stopPropagation();
                event.preventDefault();
            }
        }
    },
    computed: {
        valid() {
            return this.field.required && this.value !== null;
        },
        index() {
            const option = this.field.options.find(option => option[0] === this.value);
            return this.field.options.indexOf(option);
        },
        checkedElem() {
            return this.$el.querySelectorAll('[role="radio"]')[this.index];
        }
    },
    watch: {
        value(value) {
            this.$parent.$emit('value::set', {name: this.field.name, value});
            this.$nextTick(() => {
                if (this.checkedElem)
                    this.checkedElem.focus();
            });
        }
    },
}
</script>
<style lang="sass">
@import ../../style/variables
@import ../../style/mixins

.radio-buttons
    input
        display: none
    label
        font-size: 17px
        +media-breakpoint-up(sm)
            font-size: 19px
        color: lighten($color-dark, 15%)

    .radio-display
        display: inline-block
        position: relative
        top: 5px
        width: 24px
        height: 24px
        background-color: #fff
        border-radius: 50%
        border: 1px solid #000
        margin-right: .4rem
        margin-left: .4rem

    [aria-checked] .radio-display::after
        content: ""
        display: inline-block
        position: absolute
        left: 6px
        top: 6px
        width: 10px
        height: 10px
        background-color: darken($color-mint, 50%)
        border-radius: 50%

</style>
