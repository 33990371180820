const p = str => '<p>' + str + '</p>';

export default {
    data() {
        return {
            active: false
        };
    },
    props: ['id'],
    methods: {
        toggle() {
            if (this.active) {
                this.$root.$refs.savedCards.remove(this.id);
            } else {
                if (localStorage.length)
                    return this.$root.$refs.savedCards.save(this.id);
                this.$root.$emit('confirm::open', {
                    title: this.$gettext('Save data?'),
                    description: p(this.$gettext('When saving advice on Näthatshjälpen, information will be stored in your browser.')) + p(this.$gettext('When you go to the page with saved advice, the list is also sent to our server. We only use that information to be able to display your list of saved advice.')),
                    buttons: [
                        {
                            title: this.$gettext('Cancel'),
                            type: 'secondary'
                        },
                        {
                            title: this.$gettext('Ok'),
                            type: 'primary',
                            callback: () => {
                                this.$root.$refs.savedCards.save(this.id);
                            }
                        }
                    ]
                });
            }
        },
        set_active(cards) {
            this.active = cards.includes(this.id);
        }
    },
    created() {
        this.set_active(this.$root.$refs.savedCards.savedCards);
        this.$root.$refs.savedCards.$on('updateCards', this.set_active);
    }
}
