<template>
    <div class="text-input" :class="{invalid: touched && !valid}">
        <h2><label :for="field.name">{{ field.label }}</label></h2>
        <help-text :field="field"/>
        <input @keypress.enter.prevent @blur="touched = true" :required="field.required" class="form-control" :min="field.min" :max="field.max" :id="field.name" :type="type" :name="field.name" v-model="value" />
        <span class="validation" v-if="touched && !valid"><span class="exclamation">!</span> {{ validity }}</span>
        <help-collapse :field="field"/>
    </div>
</template>
<script>
import HelpText from './HelpText';
import HelpCollapse from './HelpCollapse';
export default {
    data() {
        return {
            value: this.field.value,
            valid: true,
            touched: false,
            type: 'text',
            tagName: 'input'
        }
    },
    props: {
        field: Object
    },
    components: {
        HelpText,
        HelpCollapse
    },
    computed: {
        inputElem() {
            return this.$el.querySelector(this.tagName);
        },
        validity() {
            const validity = this.inputElem.validity;
            return this.field.errorMessage ||
                (validity.valueMissing ? 'Detta fält måste fyllas i' : 'Felaktigt värde');
        }
    },
    mounted() {
        this.valid = this.inputElem.validity.valid;
    },
    watch: {
        value(value) {
            this.valid = this.inputElem.validity.valid;
            if (this.valid)
                this.$parent.$emit('value::set', {name: this.field.name, value});
            else
                this.$parent.$emit('value::set', {name: this.field.name, value: null});
        }
    }
}
</script>
<style lang="sass">
@import ../../style/variables

.text-input
    input
        border: 1px solid #000
        border-radius: 0
        height: 3rem
        &::-webkit-inner-spin-button
            display: none
    &.invalid input
        border-bottom: 4px solid #000
    h2
        margin-bottom: 0

.validation
    display: inline-block
    margin-top: .4rem
    .exclamation
        display: inline-block
        width: 18px
        height: 18px
        font-size: 14px
        background-color: #ee98a1
        text-align: center
        font-weight: 500
</style>
