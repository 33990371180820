// import $ from 'jquery';

export default {
    props: ['id'],
    methods: {
        remove() {
            // let $remainCards = $(this.$el).parents('.nathat-card').siblings('.nathat-card');
            this.$root.$refs.savedCards.remove(this.id);
            location.href = this.$root.$refs.savedCards.savedUrl;
            /*
            $(this.$el).parents('.nathat-card').remove();
            history.replaceState({}, '', this.$root.$refs.savedCards.savedUrl);
            $remainCards.each((i, card) => {
                $(card).find('.num').html(i+1);
            });
            */
        }
    }
}
