import $ from 'jquery';

export default {
    props: {
        href: String,
    },
    data: () => {
        return {
            savedCards: [],
            savedUrl: '',
        };
    },
    methods: {
        save(id) {
            this.savedCards.push(id);
            this.update();
            $('body').addClass('scrolled-up');
            if (!$('#saved-cards-dialog').hasClass('show')) {
                this.$root.$emit('bv::toggle::collapse', 'saved-cards-dialog')
            }
        },
        remove(id) {
            let pos = this.savedCards.indexOf(id);
            if (pos !== -1) {
                this.savedCards.splice(pos, 1);
            }
            this.update();
        },
        update_url() {
            this.savedUrl = this.href + '?a=' + this.savedCards.join(',');
        },
        update() {
            this.$emit('updateCards', this.savedCards);
            if (this.savedCards.length)
                localStorage.savedCards = JSON.stringify(this.savedCards);
            else
                delete localStorage.savedCards;
            this.update_url();
        }
    },
    created() {
        if (localStorage.savedCards)
            this.savedCards = JSON.parse(localStorage.savedCards);
        this.update_url();
        this.$root.$on('clearBrowserData', () => {
            this.savedCards = [];
            this.update();
        });
    },
    mounted() {
        const $dl = $('#saved-download');
        const base_url = $dl.attr('href');
        if ($dl.length === 1) {
            const urlCards = new URLSearchParams(location.search).get('a') || '';
            $dl.attr('href', base_url + location.search);
            this.savedCards = urlCards.split(',').map(n => parseInt(n));
            this.$on('updateCards', () => {
                $dl.attr('href', base_url + '?a=' + this.savedCards.join(','));
            });
            this.update();
        }
    }
}
