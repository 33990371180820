<template>
    <p v-if="field.helpText && !field.helpLabel" class="help">
        {{ field.helpText }}
    </p>
</template>
<script>
export default {
    props: {
        field: Object
    }
}
</script>
<style lang="sass">
@import ../../style/variables

p.help
    color: #414141
    margin-bottom: .3rem
</style>
