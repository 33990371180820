import RadioButtons from './RadioButtons.vue';
import RadioList from './RadioList.vue';
import Text from './Text.js';
import TextArea from './TextArea.vue';
import Date from './Date.js';
import InfoText from './InfoText.vue';

export default {
    RadioButtons,
    RadioList,
    Text,
    TextArea,
    Date,
    InfoText
}
