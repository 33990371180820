<template>
    <article class="row">
        <header class="col-lg-8 offset-lg-2">
            <span class="label">{{ $gettext('Police report - step by step') }}</span>
            <h1>{{ number }}. {{ data.title }}</h1>
            <p>{{ $gettext("Now you can send your crime report by opening the form in your email app. Don't forget to complete with your personal information, and attach any images or screenshots before pressing send. The form will point out where it needs to be completed.") }}</p>
        </header>
        <div class="col-lg-8 offset-lg-2">
            <div class="email-or-copy">
                <a class="btn btn-primary" target="_blank" :href="'mailto:' + recipient + '?subject=Brottsanmälan&body=' + encodeURIComponent(emailText)">{{ $gettext('Open in email app') }}</a>
                <button class="btn btn-secondary" @click="setClipboard(emailText)">{{ $gettext('Copy text') }}</button>
                <button class="btn btn-secondary" @click="setClipboard(recipient)">{{ $gettext('Copy email address to the police') }}</button>
            </div>
            <div id="email-text">
                <header>*** = {{ $gettext('Replace with your own content, e.g. personal data or image attachments') }}</header>
                <p>{{ emailText }}</p>
            </div>
        </div>
        <div class="col-lg-8 offset-lg-2 navigation">
            <button @click.prevent="previous" class="btn btn-secondary">{{ $gettext('Previous') }}</button>
            <button @click.prevent="next" class="btn btn-primary">{{ $gettext('Done') }}</button>
        </div>
    </article>
</template>
<script>
import Base from './Base.vue';

export default {
    extends: Base,
    methods: {
        setClipboard(value) {
            var tempInput = document.createElement("textarea");
            tempInput.style = "position: absolute; left: -1000px; top: -1000px;";
            tempInput.value = value;
            document.body.appendChild(tempInput);
            tempInput.select();
            document.execCommand("copy");
            document.body.removeChild(tempInput);
        }
    },
    computed: {
        formData() {
            let data = {};
            this.$parent.stepData.forEach(step => {
                step.fields.forEach(field => {
                    data[field.name] = field.value;
                });
            });
            return data;
        },
        recipient() {
            return this.formData.policeRegion.email;
        },
        emailText() {
            return this.$parent.template
            .replace(/\{%\s*if\s*(.*?)\s*%}(.*?)\{%\s*endif\s*%}/gism, (s, condition, text) => {
                return this.formData[condition] ?
                    text : '';
            })
            .replace(/{{\s*([\w.]+)\s*}}/gi, (s, m) => {
                const args = m.split('.');
                let value = this.formData;
                while (args.length) {
                    value = value[args.shift()];
                }
                return value
            });
        }
    }
}
</script>
<style lang="sass">
@import ../../../style/variables

.email-or-copy
    .btn
        border-radius: 0
        background-color: $color-mint
        border: none
        color: #000
        font-size: 19px
        font-weight: 500
        padding: 1.2rem 1.6rem 1rem
        line-height: 1
        margin: 0 1.6rem 1.2rem 0
        &:disabled
            background-color: lighten($color-mint, 2%)

#email-text
    background-color: #fff
    border: 1px solid #000
    header
        text-align: center
        background-color: #f7f7f7
        border-bottom: 1px solid #ddd
        font-size: 19px
        padding: .7rem 0 .4rem
        margin: 0
    p
        padding: 1rem
        font-size: 14px
        white-space: pre-line
        font-family: monospace
</style>
