<template>
    <div class="radio-list table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <th v-for="(header, k) in field.headers" :key="k">{{ header }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(option, k) in field.options" :key="k" role="radio" @keydown="keydown" :tabindex="(field.value !== null ? field.value === option[0] : k === 0) ? 0 : -1" :aria-checked="value === option[0]" @click="value = option[0]">
                    <td v-for="(col, k) in option.slice(1)" :key="k">
                        <span v-if="k===0" class="radio-display"></span>
                        {{ col }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import RadioButtons from './RadioButtons';
export default {
    extends: RadioButtons,
}
</script>
<style lang="sass">
@import ../../style/variables
@import ../../style/mixins

.radio-list
    font-size: 17px
    +media-breakpoint-up(sm)
        font-size: 19px

    th
        font-weight: 500
        background-color: lighten($color-mint, 10%)
        font-size: 15px
        padding: 1.4rem .8rem .4rem
        +media-breakpoint-up(sm)
            font-size: 20px
            padding: 1.4rem 1rem .6rem
            &:first-child
                padding-left: 2rem

    th, td
        border-bottom: 1px solid #000 !important
        border-top: 0

    td
        padding: .8rem .8rem .4rem
        font-size: 13px
        +media-breakpoint-up(sm)
            padding: 1rem 1rem .8rem
            font-size: 19px

    .radio-display
        display: inline-block
        position: relative
        top: 7px
        width: 18px
        height: 18px
        margin: -10px .2rem .2rem 0
        +media-breakpoint-up(sm)
            width: 24px
            height: 24px
            margin: -10px .4rem 0 0
        background-color: #fff
        border-radius: 50%
        border: 1px solid #000

    [aria-checked]
        td
            background-color: lighten($color-mint, 7%)
        .radio-display::after
            content: ""
            display: inline-block
            position: absolute
            left: 4px
            top: 4px
            width: 8px
            height: 8px
            +media-breakpoint-up(sm)
                left: 6px
                top: 6px
                width: 10px
                height: 10px
            background-color: darken($color-mint, 50%)
            border-radius: 50%

</style>
