import $ from 'jquery';

import SupportResults from './SupportResults.vue';

export default {
    data() {
        return {
            filters: [],
            loaded: false,
            items: [],
            filteredItems: [],
            allSelectedTags: [],
            tagDict: {}
        }
    },
    props: {
        src: String
    },
    components: {
        SupportResults
    },
    methods: {
        toggle(tag) {
            tag.selected = !tag.selected;
            this.applyFilter();
        },
        clearFilter(filter) {
            filter.tags.forEach(tag => tag.selected=false);
            this.applyFilter();
        },
        applyFilter() {
            this.allSelectedTags = this.filters.reduce((acc, curr) => {
                return acc.concat(curr.tags.filter(tag => tag.selected));
            }, []);
            if (this.allSelectedTags.length) {
                this.filteredItems = this.items.filter(item => {
                    let score = 0;
                    this.filters.forEach(filter => {
                        if (isNaN(score))
                            return;
                        const theseTags = filter.tags.filter(tag => tag.selected).map(tag => tag.slug);
                        if (theseTags.length)
                            score += (item.tags.filter(tag => theseTags.includes(tag)).length || NaN);
                    });
                    item.score = score;

                    return !isNaN(score);
                }).sort((a,b) => b.score - a.score);
            } else {
                this.filteredItems = this.items;
            }
            this.$root.$emit('support::filteredItems', this.filteredItems);
        }
    },
    computed: {
        currentTags() {
            return this.allSelectedTags.map(tag => tag.slug);
        }
    },
    created() {
        $.get(this.src)
        .done(data => {
            this.items = data.items;
            this.filters = data.filters;
            this.filters.forEach(filter => {
                filter.tags.forEach(tag => this.tagDict[tag.slug] = tag.title);
            });
            this.loaded = true;
            this.applyFilter();
        });
    },
};
