import $ from 'jquery';

const DEFAULT_OPTIONS = {
    description: 'Something happened.',
    buttons: [{title: 'Ok', type: 'primary'}]
}

export default {
    data() {
        return {
            active: false,
            options: DEFAULT_OPTIONS,
            lastFocus: null
        }
    },
    methods: {
        btnClick(btn) {
            if (btn.callback)
                btn.callback();
            this.close();
        },
        open() {
            this.active = true;
            this.lastFocus = $(':focus');
            this.$nextTick(() => {
                $(this.$el).find('button').first().focus();
            });
        },
        close() {
            this.active = false;
            this.lastFocus.focus();
        }
    },
    mounted() {
        this.$root.$on('confirm::open', options => {
            this.options = $.extend({}, DEFAULT_OPTIONS, options);
            this.open();
        });
        $(document).keyup(event => {
            if (this.active && event.key === 'Escape')
                this.close();
        });
        $(document).focusout(event => {
            if (this.active) {
                const target = event.relatedTarget;
                if (!this.$el.contains(target)) {
                    $(this.$el).find('button').first().focus();
                }
            }
        });
    }
}
