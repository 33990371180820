<template>
    <div v-if="field.helpText && field.helpLabel" class="help">
        <a :href="'#' + field.name" @click.prevent v-b-toggle="'help-collapse-' + field.name">{{ field.helpLabel }}</a>
        <b-collapse :id="'help-collapse-' + field.name" class="help-text">{{ field.helpText }}</b-collapse>
    </div>
</template>
<script>
export default {
    props: {
        field: Object
    }
}
</script>
<style lang="sass">
@import ../../style/variables
@import ../../style/mixins

div.help
    margin-top: .5rem
    font-size: 18px
    +media-breakpoint-up(sm)
        font-size: 20px
    a
        color: $color-mint-dark
        font-weight: 500
        text-decoration: underline
        &::before
            content: ''
            background: url('../../assets/caret-mint-dark.svg') center/100% no-repeat
            width: 14px
            height: 14px
            display: inline-block
            margin-right: .6rem
            transform: rotate(180deg)
            transition: transform .2s
        &.collapsed::before
            transform: rotate(0deg)

    .help-text
        padding-left: 35px
        +media-breakpoint-up(sm)
            padding-right: 60px
        padding-bottom: 10px
        border-left: 3px solid darken($color-mint, 30%)
        margin-top: 1rem
        white-space: pre-line
        font-size: 17px
        +media-breakpoint-up(sm)
            font-size: 19px
</style>
